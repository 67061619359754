import React, { useContext, useRef, useEffect } from 'react';

import { AppContext } from '../../providers/App.provider';
import TextBlock from '../text-block/TextBlock';
import { ReactComponent as ImagineLogo } from '../../../img/imagine_logo.svg';

const Footer = () => {
  const { apiData } = useContext(AppContext);
  const { title, text, legal_text } = apiData.static.acf;
  const { items } = apiData.footerMenu;

  const footerRef = useRef(null);

  const handleScroll = () => {
    const isFooterVidible = footerRef.current.getBoundingClientRect().top - window.innerHeight < 0;

    if (isFooterVidible && !document.body.classList.contains('footer--isVisible'))
      document.body.classList.add('footer--isVisible');

    if (!isFooterVidible && document.body.classList.contains('footer--isVisible'))
      document.body.classList.remove('footer--isVisible');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
      document.body.classList.remove('footer--isVisible');
    };
  }, []);

  return (
    <footer id="contacts" className="footer" ref={footerRef}>
      <div className="container">
        <div className="grid">
          <div className="grid__col grid__col--l">
            <span className="sub-title sub-title--light">{title}</span>
          </div>
          <div className="grid__col grid__col--r">
            <TextBlock innerHTML={text} />
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom--l">
            <div className="footer__legal" dangerouslySetInnerHTML={{ __html: legal_text }}></div>
            <ul className="list list--foot-nav">
              {items.map(({ ID, url, post_title }) => (
                <li className="list__item" key={ID}>
                  <a href={url} target="_blank" rel="noopener" className="link link--white link--reverse">
                    {post_title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="footer__created-by">
            <p>Created by</p>
            <a href="https://www.imagine.lt" target="_blank">
              <ImagineLogo />
            </a>
          </div> */}
        </div>
      </div>

      <div className="footer__decor">
        <div className="footer__decor--inner"></div>
      </div>
    </footer>
  );
};

export default Footer;
