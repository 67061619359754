import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../components/loader/Loader';

export const AppContext = createContext({
  apiData: { pages: [], static: null, headerMenu: null, footerMenu: null },
  currentlang: '',
  switchLang: () => {},
  getLangURLParam: () => {},
  languages: null,
  menuOpen: false,
  toggleMenu: () => {},
});

const AppProvider = ({ children }) => {
  const [apiData, setApiData] = useState({ pages: [], static: null, headerMenu: null, footerMenu: null });
  const [isLoading, setIsLoading] = useState(true);
  const [currentlang, setCurrentlang] = useState(window.settings.defaultLang || 'lt');
  const [languages, setLanguages] = useState([
    { render: 'LT', slug: 'lt' },
    { render: 'EN', slug: 'en' },
  ]);
  const [menuOpen, setMenuOpenStatus] = useState(false);

  useEffect(() => {
    fetchAPI();
  }, [currentlang]);

  const switchLang = (lang) => {
    setCurrentlang(lang);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  }, [menuOpen]);

  const fetchAPI = () => {
    const footer = `${window.settings.endpointBase}${getLangURLParam()}/wp-json/acf/v3/options/options`;
    const pages = `${window.settings.endpointBase}${getLangURLParam()}/wp-json/wp/v2/pages`;
    const headerMenu = `${window.settings.endpointBase}${getLangURLParam()}/wp-json/menus/v1/menus/header`;
    const footerMenu = `${window.settings.endpointBase}${getLangURLParam()}/wp-json/menus/v1/menus/footer`;

    const requestFooter = axios.get(footer);
    const requestPages = axios.get(pages);
    const requestHeaderMenu = axios.get(headerMenu);
    const requestFooterMenu = axios.get(footerMenu);

    axios
      .all([requestPages, requestFooter, requestHeaderMenu, requestFooterMenu])
      .then(
        axios.spread((...responses) => {
          setApiData({
            pages: responses[0].data,
            static: responses[1].data,
            headerMenu: responses[2].data,
            footerMenu: responses[3].data,
          });
          setIsLoading(false);
        })
      )
      .catch((error) => console.log(error));
  };

  const getLangURLParam = () => {
    return currentlang === 'lt' ? '/' : `/${currentlang}`;
  };

  const toggleMenu = () => {
    setMenuOpenStatus(!menuOpen);
  };

  if (isLoading) return <Loader />;

  return (
    <AppContext.Provider
      value={{
        currentlang,
        switchLang,
        getLangURLParam,
        apiData,
        languages,
        menuOpen,
        toggleMenu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
