import React, { useContext } from 'react';
import CookieConsent from 'react-cookie-consent';

import { AppContext } from '../../providers/App.provider';
import TextBlock from '../text-block/TextBlock';

const Cookie = () => {
  const { apiData } = useContext(AppContext);
  const { cookie_agree_button, cookie_text } = apiData.static.acf;

  return (
    <CookieConsent
      disableStyles={true}
      buttonClasses="cookie__button"
      containerClasses="cookie"
      contentClasses="cookie__body"
      buttonText={cookie_agree_button}
      cookieName="privacy"
      expires={356}
    >
      <TextBlock innerHTML={cookie_text} />
    </CookieConsent>
  );
};

export default Cookie;
