import React from 'react';
import ReactDOM from 'react-dom';

import './scss/style.scss';
import App from './app/App.jsx';
import AppProvider from './app/providers/App.provider.jsx';

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root')
);
