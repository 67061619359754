import React from 'react';
import { ReactComponent as DownloadIcon } from '../../../img/ic_download.svg';

const CareerList = ({ list }) => {
  return (
    <ul className="list list--career">
      {list.map((item, index) => (
        <li className="list__item" key={index}>
          <a className="list__download" href={item.file} target="_blank" rel="noopener noreferrer">
            <div className="text-block">
              <h3>{item.title}</h3>
              <p>{item.city}</p>
            </div>
            <span className="icon">
              <DownloadIcon />
            </span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default CareerList;
