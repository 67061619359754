import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-scroll';

import { AppContext } from '../../providers/App.provider';
import Logo from '../logo/Logo';
import LangSwitch from '../lang-switch/LangSwitch';

const Header = () => {
  const [isSticky, setSticky] = useState(false);

  const {
    apiData: {
      headerMenu: { items },
    },
    toggleMenu,
  } = useContext(AppContext);

  const handleScroll = () => {
    setSticky(window.pageYOffset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <div className="container">
        <div className="header__inner">
          <Logo />
          <nav className="header__nav">
            <ul className="list list--nav">
              {items.map(({ ID, post_title, url }) => (
                <li key={ID} className="list__item">
                  <Link
                    to={url.split('#')[1]}
                    spy={true}
                    smooth={true}
                    className={`link link--green ${url === '#contacts' ? 'link--contacts' : ''}`}
                  >
                    {post_title}
                  </Link>
                </li>
              ))}
            </ul>
            {/* <LangSwitch /> */}
          </nav>
          <button aria-label="Mobile menu" className="header__menu" onClick={toggleMenu}>
            <span>
              <i></i>
            </span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
