import React, { useState, useEffect } from 'react';

import TextBlock from '../text-block/TextBlock';
import ShowMore from '../show-more/ShowMore';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../../img/ic_close.svg';

const checkIsMobile = () => {
  return window.innerWidth < 768;
};

const PoepleList = ({ list }) => {
  const [showMore, setShowMore] = useState(true);
  const [isMobile, setMobile] = useState(checkIsMobile);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', () => handleResize);
    };
  }, []);

  const handleResize = () => {
    setMobile(checkIsMobile());
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <React.Fragment>
      <ul className="list list--people">
        {list
          .filter((i, idx) => (isMobile && showMore ? idx <= 2 : true))
          .map((item, index) => (
            <li className="list__item" key={index}>
              <div className="avatar" style={{ backgroundImage: `url(${item.image})` }}></div>
              <div className="text-block">
                <h3
                  className="text-block__header-link"
                  style={{ marginBottom: '0.5rem' }}
                  onClick={() => {
                    openModal();
                    console.log(item);
                    setModalData(item);
                  }}
                >
                  {item.name}
                </h3>
              </div>
              <TextBlock innerHTML={item.details} />
            </li>
          ))}
      </ul>
      {isMobile ? <ShowMore showMore={showMore} toggleShowMore={toggleShowMore} /> : null}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <button onClick={closeModal} className="ReactModal__close">
          <CloseIcon />
        </button>
        <span className="sub-title">{modalData.name}</span>
        <TextBlock innerHTML={modalData.modal_text} />
      </Modal>
    </React.Fragment>
  );
};

export default PoepleList;
