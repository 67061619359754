import React from 'react';

import Modal from 'react-modal';
import TextBlock from '../text-block/TextBlock';
import { ReactComponent as CloseIcon } from '../../../img/ic_close.svg';

Modal.setAppElement('#root');

const ActivityList = ({ list }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ul className="list list--activity">
      {list.map((item, index) => (
        <li
          onClick={() => {
            openModal();
            setModalData(item);
          }}
          className="list__item"
          key={index}
        >
          <span className="icon" style={{ backgroundImage: `url(${item.icon})` }}>
            <span className="icon--hover" style={{ backgroundImage: `url(${item.icon_hover})` }}></span>
          </span>
          <h3 className="heading heading--s">{item.title}</h3>
        </li>
      ))}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <button onClick={closeModal} className="ReactModal__close">
          <CloseIcon />
        </button>
        <span className="sub-title">{modalData.title}</span>
        <TextBlock innerHTML={modalData.modal_text} />
      </Modal>
    </ul>
  );
};

export default ActivityList;
