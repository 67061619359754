import React from 'react';

import ScrollTo from '../scroll-to/ScrollTo';

const SectionHero = ({ content }) => {
  return (
    <section className="hero section">
      <div className="container">
        <div className="hero__container">
          <div className="hero__wrapper">
            <div className="hero__content">
              <h1 className="heading heading--l">{content.title}</h1>
            </div>
            <ScrollTo />
          </div>
          <div className="hero__wrapper">
            <div className="hero__image">
              <img src={content.hero_image} alt={content.title} />
            </div>
          </div>
        </div>
      </div>

      <div className="hero__decor">
        <div className="hero__decor--inner"></div>
      </div>
    </section>
  );
};

export default SectionHero;
