import React, { useContext } from 'react';
import { Link } from 'react-scroll';
import { ReactComponent as ArrowDown } from '../../../img/ic_arrow_down.svg';

import { AppContext } from '../../providers/App.provider';

const ScrollTo = () => {
  const { apiData } = useContext(AppContext);
  return (
    <Link className="scroll-to" to="activity" spy={true} smooth={true} duration={500}>
      <ArrowDown />
      <span>{apiData.static.acf.scroll_to}</span>
    </Link>
  );
};

export default ScrollTo;
