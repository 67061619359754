import React from 'react';
import Sticky from 'react-stickynode';

import TextBlock from '../text-block/TextBlock';
import ActivityList from '../list/list.activity';
import PeopleList from '../list/list.people';
import CareerList from '../list/list.career';

const Section = ({ title, text, people, activity, career, select_sub_items, section_id }) => {
  return (
    <section className="section" id={select_sub_items ? select_sub_items.toLowerCase() : section_id}>
      <div className="container">
        <div className="grid">
          <div className="grid__col grid__col--l">
            <Sticky
              enabled={window.innerWidth >= 768 ? true : false}
              top={120}
              bottomBoundary={`#${select_sub_items ? select_sub_items.toLowerCase() : section_id}`}
            >
              <span className="sub-title">{title}</span>
            </Sticky>
          </div>
          <div className="grid__col grid__col--r">
            <TextBlock innerHTML={text} />
            {activity ? <ActivityList list={activity} /> : null}
            {people ? <PeopleList list={people} /> : null}
            {career ? <CareerList list={career} /> : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
