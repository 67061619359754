import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { AppContext } from '../../providers/App.provider';
import HeroSection from '../../components/section/Section.hero';
import Section from '../../components/section/Section.grid';

const Homepage = ({ match }) => {
  const { apiData, switchLang } = useContext(AppContext);
  const homepage = apiData.pages.filter((page) => page.slug === 'pagrindinis');
  const { acf } = homepage[0];

  useEffect(() => {
    switchLang(match.params.lng || 'lt');
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{acf.seo_title}</title>
        <meta name="description" content={acf.seo_description} />
        <meta name="robots" content="index, follow" />
        <meta name="google-site-verification" content="CdUjQV_voBLPSaH3nXwBvwAedx4mAeHCA58nVFWdtTY" />
      </Helmet>
      <main>
        <HeroSection content={acf.hero} />
        <Sections sections={acf.hero_sections} />
        <Banner image={acf.banner_image} />
      </main>
    </React.Fragment>
  );
};

const Sections = ({ sections }) => {
  if (!sections || !sections.length) return null;

  return sections.map((section, index) => {
    return <Section key={index} {...section} />;
  });
};

const Banner = ({ image }) => {
  if (!image) return null;

  return (
    <div className="section section--banner">
      <div className="banner">
        <div className="banner__image">
          <img src={image.url} alt={image.alt} />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
