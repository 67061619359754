import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Homepage from './pages/homepage/HomePage';
import Cookie from './components/cookie/Cookie';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path={`/:lng(en|lt)?`} component={Homepage} />
          <Redirect from="/" to="/lt" />
        </Switch>
        <Footer />
      </Router>
      <Cookie />
    </div>
  );
};

export default App;
