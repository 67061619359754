import React, { useContext } from 'react';

import { AppContext } from '../../providers/App.provider';
import { ReactComponent as Arrow } from '../../../img/ic_arrow.svg';

const ShowMore = ({ showMore, toggleShowMore }) => {
  const {
    apiData: {
      static: { acf },
    },
  } = useContext(AppContext);

  return (
    <div className={`show-more show-more--${showMore ? 'more' : 'less'}`}>
      <button onClick={toggleShowMore} className="show-more__button">
        {showMore ? acf.show_more : acf.show_less}
        <span className="show-more__arrow">
          <Arrow />
        </span>
      </button>
    </div>
  );
};

export default ShowMore;
